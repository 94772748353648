export default {
    namespaced: true,
    state: {
        baseURL : 'https://api.bankbongah.com',
        addEstate: {
            isShowBack : true
        },
        url : 'https://app.bankbongah.com'
    },
    getters: {},
    mutations: {
        CHANGE_IS_SHOW_BACK(state , data){
            state.addEstate.isShowBack = data
        }
    },
    actions: {}
}