import store from '../store'

export const CustomerRoutes = [
    {
        path : '/customer/',
        name : 'HomeCustomer',
        component : () => import('../views/customer/Home'),
        meta :{
            isCustomer : true
        },
    },
    {
        path : '/customer/add-request/',
        name : 'AddRequestCustomer',
        component : () => import('../views/customer/AddRequest'),
        beforeEnter: (to, from, next) => {
            let contact = store.state.addRequest.contact
            let city = store.state.addRequest.city
            if (to.name === 'AddRequestCustomer') next({name : 'AddRequestStep1'})
            else if (to.name === 'AddRequestStep2' && contact === '')next({name : 'AddRequestStep1'})
            else if (to.name === 'successRequest' && from !== 'AddRequestStep2') next({name : 'HomeCustomer'})
            else if (to.name === 'selectRealestate' && from !== 'successRequest') next({name : 'HomeCustomer'})
            else next();
        },
        meta :{
            isCustomer : true
        },
        children :[{
            path : 'step1',
            name : 'AddRequestStep1',
            component : () => import('../components/customerAd/Step1'),
        },{
            path : 'step2',
            name : 'AddRequestStep2',
            component : () => import('../components/customerAd/Step2'),
        },{
            path : 'success-request',
            name : 'successRequest',
            component : () => import('../components/customerAd/successRequest'),
        },{
            path : 'select-realestate',
            name : 'selectRealestate',
            component : () => import('../components/customerAd/SelectRealEstates'),
        },
        {
            path : 'step2/select-city',
            name : 'RequestAdSelectCity',
            component : () => import('../components/customerAd/SelectCity'),

        },
        {
            path : 'step2/map',
            name : 'RequestAdMap',
            component : () => import('../components/customerAd/MapFull'),

        }]
    },
    {
        path : '/customer/estate/:id',
        name : 'EstateCustomer',
        component : () => import('../views/customer/EstateDetail'),
        meta :{
            isCustomer : true
        },
    },
    {
        path : '/customer/profile',
        name : 'ProfileCustomer',
        component : () => import('../views/customer/Profile'),
        meta :{
            isCustomer : true
        },
    },
    {
        path : '/customer/map',
        name : 'LocationCustomer',
        component : () => import('../views/customer/Map'),
        meta :{
            isCustomer : true
        },
    },{
        path : '/customer/page/:id',
        name : 'RealEstatePageCustomer',
        component : () => import('../views/customer/RealEstatePage'),
        meta :{
            isCustomer : true
        },
    },{
        path: '/customer/select-area',
        name: 'SelectAreaCustomer',
        component: () => import('../views/customer/SelectCity.vue'),
        meta :{
            isCustomer : true
        },
    },{
        path: '/customer/select-city',
        name: 'SelectCityCustomer',
        component: () => import('../views/customer/SelectCityMap.vue'),
        meta :{
            isCustomer : true
        },
    },
    {
        path: '/customer/find-realEstate',
        name: 'FindRealEstateCustomer',
        component: () => import('../views/customer/FindRealEstates.vue'),
        meta :{
            isCustomer : true
        },
    },{
        path: '/customer/notifications',
        name: 'NotificationsCustomer',
        component: () => import('../views/customer/Notifications.vue'),
        meta :{
            isCustomer : true
        },
    },{
        path: '/customer/notifications/:id',
        name: 'NotificationsDetailCustomer',
        component: () => import('../views/customer/NotificationDetail'),
        meta :{
            isCustomer : true
        },
    },

]