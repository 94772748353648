import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { GeneralRoutes } from './generalRoutes'
import { CustomerRoutes } from './customerRoutes'
import { RealEstateRoutes } from './realEstateRoutes'
Vue.use(Router);

const routes = [
    ...RealEstateRoutes,
    ...CustomerRoutes,
    ...GeneralRoutes,
]

const router = new Router({
    mode: 'history',
    routes,
    base: process.env.BASE_URL,
})

router.beforeEach((to, from, next) => {
    const phone = store.state.auth.auth.phone;
    const token = store.state.auth.auth.token;
    let user = JSON.parse(localStorage.getItem('user'))

    if (to.name === 'UserVerify' && phone === '') next({name: 'UserLogin'})
    else next()
    if (to.name === 'Signup' && token === '') next({name : 'RealEstateVerify'})
    else next()
    if ('user' in localStorage){
        if (to.meta.isRealEstate && user.type !== 'real-estate')next('/')
        else next();
        if (to.meta.isCustomer && user.type !== 'user')next('/')
        else next();
    }else {
        if (to.meta.isRealEstate || to.meta.isCustomer) next({name : 'LoginMode'})
    }

})
router.afterEach((to, from) => {

    //router.app.$isLoading(false)
    // Complete the animation of the route progress bar.
})
export default router
