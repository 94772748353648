
const getDefaultState = () => {
    return  {
        postal_code: '',
        advertisement_type: '',
        usage: [],
        house_type: '',
        geoposition : null,
        city : '',
        province : '',
        address : '',
        area :  '',
        title : '',
        description : '',
        options :{
            area : '',
            price : 0,
            age : 0,
            downPayment : 0,
            rent_price : 0,
            facade : 'شمالی',
            number_of_washroom : 0,
            number_of_bathrooms : 0,
            number_of_parking : 0,
            discharge_date : 0,
            delivery_date : 0,
            instalment_amount : null,
            instalment_count : null,
            number_of_iranian_services : 0,
            number_of_bedrooms : 0,
            width_country: null,
            property_position: 'درب ساختمان',
            width_of_passage: null,
            property_length: null,
            view_type: null,
            apartment_unit_type: 'فلت',
            building_type: null,
            license_type: null,
            number_plates: null,
            structure_type: null,
            numbers_of_floors : 0,
            number_of_master_rooms : 0,
            number_of_units_per_floor : 0,
            number_of_kitchens : 0,
            number_of_sit_down_service : 0,
            basement : null,
            document_type : '',
            otherFeatures : {
                sepcialFeatures : []
            },
        },
        media :[]
    }
}
const state = getDefaultState();
const actions = {
    getPostalCode({commit}, data) {
        commit('SET_POSTAL_CODE', data)
    },
    getAdvertisement({commit}, data) {
        commit('SET_ADVERTISEMENT', data)
    },
    getHouseUsages({commit}, data) {
        commit('SET_HOUSE_USAGES', data)
    },
    getHouseTypes({commit}, data) {
        commit('SET_HOUSE_TYPES', data)
    },
    getAddress({commit} ,data){
        commit('SET_ADDRESS',data)
    },
    getStep5({commit},data){
        commit('SET_STEP5',data)
    },
    getImages({commit} ,data){
        commit('SET_IMAGES',data)
    }
}
const mutations = {
    SET_POSTAL_CODE(state, data) {
        state.postal_code = data
    },
    SET_ADVERTISEMENT(state, data) {
        state.advertisement_type = data
    },
    SET_HOUSE_USAGES(state, data) {
        state.usage = data
    },
    SET_HOUSE_TYPES(state, data) {
        state.house_type = data
    },
    SET_ADDRESS(state ,data){
        state.geoposition = data.geoposition;
        state.city = data.city;
        state.province = data.province;
        state.address = data.address;
        state.area = data.area
    },
    SET_STEP5(state, data){
        state.title = data.title;
        state.description = data.description;
        state.options.age = data.options.age;
        state.options.area = data.options.area;
        state.options.price = data.options.price;
        state.options.downPayment = data.options.downPayment;
        state.options.rent_price = data.options.rent_price;
        state.options.facade = data.options.facade;
        state.options.discharge_date = data.options.discharge_date;
        state.options.delivery_date = data.options.delivery_date;
        state.options.instalment_amount = data.options.instalment_amount;
        state.options.instalment_count = data.options.instalment_count;
        state.options.number_of_washroom = data.options.number_of_washroom;
        state.options.number_of_bathrooms = data.options.number_of_bathrooms;
        state.options.number_of_parking = data.options.number_of_parking;
        state.options.number_of_master_rooms = data.options.number_of_master_rooms;
        state.options.number_of_iranian_services = data.options.number_of_iranian_services;
        state.options.width_country = data.options.width_country;
        state.options.property_position = data.options.property_position;
        state.options.width_of_passage = data.options.width_of_passage;
        state.options.property_length = data.options.property_length;
        state.options.view_type = data.options.view_type;
        state.options.apartment_unit_type = data.options.apartment_unit_type;
        state.options.building_type = data.options.building_type;
        state.options.license_type = data.options.license_type;
        state.options.number_plates = data.options.number_plates;
        state.options.structure_type = data.options.structure_type;
        state.options.number_of_bedrooms = data.options.number_of_bedrooms;
        state.options.numbers_of_floors = data.options.numbers_of_floors;
        state.options.number_of_units_per_floor = data.options.number_of_units_per_floor;
        state.options.number_of_kitchens = data.options.number_of_kitchens;
        state.options.number_of_sit_down_service = data.options.number_of_sit_down_service;
        state.options.basement = data.options.basement;
        state.options.document_type = data.options.document_type;
        state.options.otherFeatures = data.options.otherFeatures;
        state.options.otherFeatures['sepcialFeatures'] = data.options.sepcialFeatures
    },
    SET_IMAGES(state, data){
        state.media = []
        state.media[0] = {
            cover: true,
            value : data.coverBase64
        }
        data.imagesBase64.forEach((item,index)=>{
            index++
            state.media[index] = {
                cover : false,
                value : item
            }
        })
    },
    CLEAR_ESTATE(state ){
        Object.assign(state, getDefaultState())
    }
}
export default {
    namespaced: true,
    state,
    getters: {},
    actions,
    mutations
}