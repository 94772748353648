import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import addEstate from "./modules/addEstate";
import map from './modules/map'
import filters from './modules/filters'
import general from "./modules/general";
import addRequest from "./modules/addRequest";
import editEstate from "./modules/editEstate";
import VuexReset from '@ianwalter/vuex-reset'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [VuexReset()],
  modules: {
    auth ,
    addEstate,
    map,
    filters,
    general,
    addRequest,
    editEstate
  }
})
