export default {
    namespaced: true,
    state: {
        province: '',
        city: '',
        contact: '',
        address: '',
        visit_time: '',
        description: '',
        geoposition: {
            latitude : 35.698,
            longitude: 51.4115
        }
    },
    getters: {

    },
    mutations: {
        SET_STEP1(state , data){
            state.contact = data.contact;
            state.visit_time = data.visit_time;
            state.description = data.description
        },
        SET_STEP2(state,data){
            state.city = data.city;
            state.province = data.province;
            state.address = data.address;
            state.geoposition = data.geoposition;
        },
        CLEAR_STATE(state){
            state.province = '',
            state.city = '',
            state.contact = '',
            state.address = '',
            state.visit_time = '',
            state.description = '',
            state.geoposition = {
                latitude : 35.698,
                longitude: 51.4115
            }
        }
    },
    actions: {

    }
}