import axios from "axios";
import router from "../router";
import Vue from 'vue'

const axiosInstance = axios.create({
    baseURL : 'https://api.bankbongah.com/api/v1',
})

axiosInstance.interceptors.request.use((config)=> {
    Vue.prototype.$isLoading(true)
    const token = localStorage.getItem('user-token')
    if (token){
        config.headers.authorization = 'Bearer ' + token
    }
    return config;
},(error) =>{
    Vue.prototype.$isLoading(false)
    // Do something with request error
    return Promise.reject(error);
});
axiosInstance.interceptors.response.use((response)=> {
    // Any status code that lie within the range of 2xx cause this function to trigger
    Vue.prototype.$isLoading(false)
    return response;
}, (error)=> {
    Vue.prototype.$isLoading(false);
    if (401 === error.response.status) {
        localStorage.removeItem('user')
        localStorage.removeItem('user-token')
        Vue.prototype.$toast.error('توکن شما معتبر نیست لطفا دوباره وارد شوید')
        router.push('/')
    } else {
        throw new handleError(error);
    }
});

function handleError(error){
    if (error.response.status === 422){
        if (error.response.data.message.code){
            this.message =  error.response.data.message.code[0]
        }else if(error.response.data.message.phone ) {
            this.message =  error.response.data.message.phone[0]
        }else{
            this.message =  error.response.data.message.value[0]
        }
    }else if (error.response.status === 503){
        this.message =  'متاسفانه مشکلی وجود دارد لطفا چند دقیقه دیگر تلاش کنید'
    }else if(error.response.status === 429){
        this.message = 'لطفا صبر کنید و پس از دو دقیقه درخواست خود را ارسال کنید'
    }else {
        this.message = 'درخواست شما با خطا مواجه شد'
    }
}

export default axiosInstance;