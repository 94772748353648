
export const GeneralRoutes = [
    {
        path: '/',
        name: 'LoginMode',
        component: () => import('../views/LoginMode.vue')
    },
    {
        path: "*",
        component: () => import('../views/PageNotFound.vue')
    },
    {
        path: '/real-estate/login',
        name: 'RealEstateLogin',
        component: () => import('../views/RealEstateLogin.vue')
    },
    {
        path: '/real-estate/signup',
        name: 'Signup',
        component: () => import('../views/RealEstateSignup.vue')
    },
    {
        path: '/real-estate/verify',
        name: 'RealEstateVerify',
        component: () => import('../views/RealEstateVerify.vue')
    },
    {
        path: '/user/login',
        name: 'UserLogin',
        component: () => import('../views/UserLogin.vue')
    },
    {
        path: '/user/verify',
        name: 'UserVerify',
        component: () => import('../views/UserVerify.vue')
    },
    {
        path: '/user/create',
        name: 'UserCreate',
        component: () => import( '../views/UserCreate.vue')
    },
    {
        path: '/real-estate/forget',
        name: 'RealEstateForget',
        component: () => import('../views/RealEstateForgetPassword.vue')
    },

]