import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/app.scss'
import loading from 'vuejs-loading-screen'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { ValidationProvider, ValidationObserver , extend ,localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import fa from 'vee-validate/dist/locale/fa.json'
import 'leaflet/dist/leaflet.js'
import 'leaflet/dist/leaflet.css'
import CurrencyInput from 'vue-currency-input'
import VueTour from 'vue-tour'
import VueSocialSharing from 'vue-social-sharing'
require('vue-tour/dist/vue-tour.css')


Vue.config.productionTip = true;
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize('fa',fa)

Vue.use(VueToast,{
    position : 'top',
});
Vue.use(loading,{
    bg : 'rgba(92,67,103,0.8)',
    slot: `<div class="text-center"><img width="100" height="100" src="/img/icons/logo.png">
<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
`

})
Vue.use(VueSocialSharing);


const pluginOptions = {
    /* see config reference */
    globalOptions: {
        currency: {
            suffix  : 'تومان'
        },
        precision : 0,
        currencyDisplay: "hidden",
    }
}
Vue.use(CurrencyInput, pluginOptions)
Vue.use(VueTour)

const app = new Vue({
    router,
    store,
    render: h => h(App)
})

app.$mount('#app')
