export default {
    namespaced: true,
    state: {
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjJjNDlhNWYwYjM5OWM0MTJkN2NjY2YyM2JiM2Q4OTQ3MzkxYjlmZjJkNmJjZTkxMDA1Yjg5YzhmMGY5YjlhMjU1YzE3ZDM1NWU1YzFjZTEyIn0.eyJhdWQiOiIxNjIwNiIsImp0aSI6IjJjNDlhNWYwYjM5OWM0MTJkN2NjY2YyM2JiM2Q4OTQ3MzkxYjlmZjJkNmJjZTkxMDA1Yjg5YzhmMGY5YjlhMjU1YzE3ZDM1NWU1YzFjZTEyIiwiaWF0IjoxNjM4MzQyNTg4LCJuYmYiOjE2MzgzNDI1ODgsImV4cCI6MTY0MDg0ODE4OCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.d-2telvcndu-6UxpFKYlCNyhgSauxlFjsXwQaCtSy0rvjn-ZSQnsFtgFEZVGINNCT9Aj6yDFURWV01AtrRn8taUxASZFMXv6fV2LInCUJfu7aOoL9fHTqJ9BoTwwLzj1Ysjf5WSX6cNm0RA1tLju8tflsppuYuIhDkGd6qBzwkf1kXww_dtx9iNhQQRMoz3oJ-zMTzQtZbTvh9RgYg9tZrNKLxkmQWHEBmzXMvMifdZ8icG-VPXxl1gn-U3bssfKuBsmMK2n_iFyQtPW29WlYbJVUx_yIZGROmxPIKmO0MpyXEu6Z9AVStgVvQf14uLGNKgXL4fH3ScXa8uTDb80Bw',
        options: {
            style: 'https://map.ir/vector/styles/main/mapir-style-dark.json',
        },
        area : [],
        selectLocation : {
            lat : null,
            lon : null
        }
    },
    getters: {},
    mutations: {
        SET_AREA(state , data){
            state.area = data
        },
        CLEAR_AREA(state , data){
            state.area = []
        },
        SET_SELECT_LOCATION(state , data){
            state.selectLocation.lat = data.lat;
            state.selectLocation.lon = data.lon
        },
        CLEAR_SELECT_LOCATION(state){
            state.selectLocation = {
                lat : null,
                lon : null
            }
        }
    },
    actions: {

    }
}