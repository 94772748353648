<template>
    <div class="mobile-size">
      <transition :name="animationName" >
      <router-view></router-view>
      </transition>
    </div>

</template>
<script>
import axios from "./plugins/axios";

export default {
  name : 'App',
  components :{
  },
  data(){
    return{
      animationName : undefined,
      backgroundColor: "#361C3B",
    }
  },
  created() {
    if (localStorage.getItem('userTour') === null){
      var data = {
        customerHome : false,
        mapCustomer : false,
        mapRealEstate : false
      }
      localStorage.setItem('userTour' , JSON.stringify(data) )
    }

  },
  watch: {
    '$route'(to, from){
      const to_depth = to.path.split('/').length
      const from_depth = from.path.split('/').length
      this.animationName = to_depth < from_depth ? 'slide-right' : 'slide-left'
    }
  }
}
</script>
<style >
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.child-view {
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
