import axios from "../../plugins/axios";
import http from 'axios'

export default {
    namespaced: true,
    state: {
        estates: [],
        filter: {
            advertisement_type: {
                operator: "IN",
                value: []
            },
            "options->otherFeatures->sepcialFeatures": {
                operator: "IN_ARRAY",
                value: []
            },
            house_type: {
                operator: "IN",
                value: []
            },
            usage: {
                operator: "IN_ARRAY",
                value: []
            },
            "options->age": {
                operator: "BETWEEN",
                value: [0, 100]
            },
            "options->area": {
                operator: "BETWEEN",
                value: [0, 10000]
            },
            "options->price": {
                operator: "BETWEEN",
                value: []
            },
            "options->downPayment": {
                operator: "BETWEEN",
                value: []
            },
            "options->rent_price": {
                operator: "BETWEEN",
                value: []
            },
            "options->number_of_bedrooms": {
                operator: "BETWEEN",
                value: [0, 7]
            },

            area: {
                operator: "IN",
                value: []
            }
        },
        estatesMap: [],
        realEstateMap : [],
        page: 1,
        lastPage: null,
        nextPage: null,
        isFilterEstate : false
    },
    getters: {
        estates: (state) => state.estates,
        page: state => state.page,
        filters : state => state.filter
    },
    mutations: {
        reset: () => {},
        CLEAR_ESTATES(state, data) {
            state.estates = []
            state.estatesMap = []
            state.page = 1;
        },
        CLEAR_ESTATES_MAP(state, data) {
            state.estatesMap = []
        },
        SET_ESTATES_WITH_FILTER(state, data) {
            state.estates.push(...data.data);
            state.lastPage = data.meta.last_page;
            state.nextPage = data.meta.current_page + 1;
        },
        SET_ESTATES_WITH_FILTER_MAP(state, data) {
            state.estatesMap.push(...data.data);
        },
        SET_REALESTATES_WITH_FILTER_MAP(state,data){
          state.realEstateMap.push(...data)
        },
        CLEAR_REALESTATE_MAP(state){
            state.realEstateMap = []
        },
        SET_FILTERS(state, data) {
            state.filter.advertisement_type.value = data.selectedAddTypes
            state.filter['options->otherFeatures->sepcialFeatures'].value = data.selectedSepcialFeatures
            state.filter.house_type.value = data.selectedHouseTypes
            state.filter.usage.value = data.selectedHouseUsages
            state.filter['options->age'].value = data.valueAgeBuilding
            state.filter['options->area'].value = data.valueFoundation
            state.filter['options->price'].value = data.valuePrice
            state.filter['options->downPayment'].value = data.valuedownPayment
            state.filter['options->rent_price'].value = data.valueRent
            state.filter['options->number_of_bedrooms'].value = data.valueNumberBedrooms
        },
        SET_FILTERS_ADDVER(state, data) {
            state.filter.advertisement_type.value = data
        },
        SET_AREA(state, data) {
            state.filter.area.value = data
        },
        SET_PAGE(state, data) {
            state.page = data
        },
        SET_GEOFILTER(state, data) {
            state.geofilter = data
        },
        SET_isFilterEstate(state , data){
            state.isFilterEstate = data
        }
    },
    actions: {
        async getData({commit }) {
            let {data} = await axios.post('estates/filter');
            commit('SET_ESTATES', data.data.data)
        },
        async getDataWithFilter({commit, state , getters }) {
            let filters = {}
            Object.entries(state.filter).forEach(item => {
                const [key, value] = item;
                if (value.value.length > 0) {
                    filters[key] = value
                }
            })
            let city = JSON.parse(localStorage.getItem('city'))
            let token = localStorage.getItem('user-token')
            if ('city' in localStorage) {
                http.post('https://api.bankbongah.com/api/v1/estates/filter?page=' + state.page, {
                    filter: {
                        ...filters,
                        city: {operator: "=", value: city.name}
                    }
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(({data}) => {
                    commit('SET_ESTATES_WITH_FILTER', data.data)
                })
            } else {
                http.post('https://api.bankbongah.com/api/v1/estates/filter?page=' + state.page, {filter: {...filters}}, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(({data}) => {

                    commit('SET_ESTATES_WITH_FILTER', data.data)
                })
            }
        },
        getDataWithFilterMap({commit, state}) {
            let filters = {}
            Object.entries(state.filter).forEach(item => {
                const [key, value] = item;
                if (value.value.length > 0) {
                    filters[key] = value
                }
            })
            let city = JSON.parse(localStorage.getItem('city'))
            axios.post('estates/filter', {
                filter: {
                    ...filters,
                    city: {
                        operator: "=",
                        value: city.name
                    },
                },
                geofilter: {
                    location: {
                        latitude: city.lat,
                        longitude: city.lng
                    },
                    radius: 50
                },
                sort: false,
                pagination: false
            }).then(({data}) => {
                commit('SET_ESTATES_WITH_FILTER_MAP', data.data)
            })
        },
        getRealEstateFilter({commit , state}){
            let city = JSON.parse(localStorage.getItem('city'));
            axios.post('real-estates/filter', {
                filter: {
                    city: {
                        operator: "=",
                        value: city.name
                    },
                },
                sort: false,
                pagination: false
            }).then(({data}) => {
                commit('SET_REALESTATES_WITH_FILTER_MAP', data.data.data)
            })
        }
    }
}