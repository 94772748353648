import store from "../store";

export const RealEstateRoutes = [
    {
        path : '/real-estate/',
        name : 'HomeRealEstate',
        component : () => import('../views/RealEstate/Home'),
        meta :{
            isRealEstate : true
        },
    },
    {
        path : '/real-estate/estate/:id',
        name : 'EstateRealEstate',
        component : () => import('../views/RealEstate/EstateDetail'),
        meta :{
            isRealEstate : true
        },
    },

    {
        path : '/real-estate/add-estate/',
        component : () => import('../views/RealEstate/AddEstate'),
        beforeEnter: (to, from, next) => {
            let user = JSON.parse(localStorage.getItem('user'))
            if (to.name !== 'PostalCode' ){
                if (store.state.addEstate.postal_code === ''){
                    next({name : 'PostalCode'})
                }
            }else{
                next();
            }
        },
        meta :{
            isRealEstate : true
        },
        children :[{
            path : '',
            name : 'PostalCode',
            component : () => import('../components/addEstate/CheckZipCode'),

        },{
            path : 'not-access',
            name : 'NotAccess',
            component : () => import('../components/addEstate/NotAccess.vue'),

        },{
            path : 'step1',
            name : 'AddEstateStep1',
            component : () => import('../components/addEstate/Step1'),
        },{
            path : 'step2',
            name : 'AddEstateStep2',
            component : () => import('../components/addEstate/Step2'),
        },{
            path : 'step3',
            name : 'AddEstateStep3',
            component : () => import('../components/addEstate/Step3'),
        },{
            path : 'step4',
            name : 'AddEstateStep4',
            component : () => import('../components/addEstate/Step4'),
        },{
            path : 'step5',
            name : 'AddEstateStep5',
            component : () => import('../components/addEstate/Step5'),
        },{
            path : 'step6',
            name : 'AddEstateStep6',
            component : () => import('../components/addEstate/Step6'),
        },{
            path : 'step7',
            name : 'AddEstateStep7',
            component : () => import('../components/addEstate/Step7'),
        },{
            path : 'finish',
            name : 'AddEstateFinish',
            component : () => import('../components/addEstate/Finish'),
        },
        {
            path : 'step4/select-city',
            name : 'AddEstateSelectCity',
            component : () => import('../components/addEstate/SelectCity'),

        },
        {
            path : 'step4/map',
            name : 'AddEstateMap',
            component : () => import('../components/addEstate/MapFull'),

        }]
    },
    {
        path : '/real-estate/profile',
        name : 'ProfileRealEstate',
        component : () => import('../views/RealEstate/Profile'),
        meta :{
            isRealEstate : true
        },
    },
    {
        path : '/real-estate/map',
        name : 'LocationRealEstate',
        component : () => import('../views/RealEstate/Map'),
        meta :{
            isRealEstate : true
        },
    },{
        path : '/real-estate/page/:id',
        name : 'RealEstatePageRealEstate',
        component : () => import('../views/RealEstate/RealEstatePage.vue'),
        meta :{
            isRealEstate : true
        },
    },{
        path: '/real-estate/select-area',
        name: 'RealEstateSelectArea',
        component: () => import(/* webpackChunkName: "loginMode" */ '../views/RealEstate/SelectCity.vue'),
        meta :{
            isRealEstate : true
        },
    },{
        path: '/real-estate/subscription',
        name: 'RealEstateSubscription',
        component: () => import(/* webpackChunkName: "loginMode" */ '../views/RealEstate/Subscription'),
        meta :{
            isRealEstate : true
        },
    },{
        path: '/real-estate/select-city',
        name: 'RealEstateSelectCity',
        component: () => import(/* webpackChunkName: "loginMode" */ '../views/RealEstate/SelectCityMap.vue'),
        meta :{
            isRealEstate : true
        },
    },{
        path: '/real-estate/find-realEstate',
        name: 'FindRealEstateRealEstate',
        component: () => import('../views/RealEstate/FindRealEstates.vue'),
        meta :{
            isRealEstate : true
        },
    },{
        path: '/real-estate/notifications',
        name: 'NotificationsRealEstate',
        component: () => import('../views/RealEstate/Notifications.vue'),
        meta :{
            isRealEstate : true
        },
    },{
        path: '/real-estate/notifications/:id',
        name: 'NotificationsDetailRealEstate',
        component: () => import('../views/RealEstate/NotificationDetail'),
        meta :{
            isRealEstate : true
        },
    },
    {
        path: '/real-estate/estate/edit/',
        name: 'EditEstate',
        beforeEnter: (to, from, next) => {
            if (from.name !== 'EstateRealEstate'){
               next({name : 'HomeRealEstate'})
            }else {
                next()
            }
        },
        component: () => import('../views/RealEstate/EditEstate.vue'),
        meta :{
            isRealEstate : true
        },
        children :[{
            path : 'step1',
            name : 'EditEstateStep1',
            component : () => import('../components/editEstate/Step1'),
        },{
            path : 'step2',
            name : 'EditEstateStep2',
            component : () => import('../components/editEstate/Step2'),
        },{
            path : 'step3',
            name : 'EditEstateStep3',
            component : () => import('../components/editEstate/Step3'),
        },{
            path : 'step4',
            name : 'EditEstateStep4',
            component : () => import('../components/editEstate/Step4'),
        },{
            path : 'step5',
            name : 'EditEstateStep5',
            component : () => import('../components/editEstate/Step5'),
        },{
            path : 'step6',
            name : 'EditEstateStep6',
            component : () => import('../components/editEstate/Step6'),
        },{
            path : 'step7',
            name : 'EditEstateStep7',
            component : () => import('../components/editEstate/Step7'),
        },{
            path : 'finish',
            name : 'EditEstateFinish',
            component : () => import('../components/editEstate/Finish'),
        },{
            path : 'step4/select-city',
            name : 'EditEstateSelectCity',
            component : () => import('../components/editEstate/SelectCity'),

        },
        {
            path : 'step4/map',
            name : 'EditEstateMap',
            component : () => import('../components/editEstate/MapFull'),
        }]
    },
]