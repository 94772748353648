export default {
    namespaced: true,
    state: {
        auth: {
            phone: '',
            token: '',
        },
        user : null,
        forget:{
            phone : '',
            code : ''
        },
        createRealEstate : {
            first_name: "",
            last_name: "",
            national_code: "",
            invite_token: '',
            real_estate: {
                name: "",
                telephone: "",
                license_id: "",
                license_picture: '',
                province: "",
                city: "",
                area_name: "",
                address: "",
                field_of_activity: [],
                geoposition: {}
            }
        },
        singUpStep : 1
    },
    getters: {
        getcreateRealEstate : state => state.createRealEstate,
        getDataRealEstate : state => state.realEstate
    },
    mutations: {
        SET_FORGET_PHONE(state, data){
            state.forget.phone = data
        },
        SET_USER_PHONE(state, data) {
            state.auth.phone = data
        },
        SET_USER_TOKEN(state ,data){
            state.auth.token = data;
        },
        SET_USER_DATA(state ,data){
            state.user = data;
        },
        SET_SIGNUP_STEP1(state , data){
            state.singUpStep = data.step
            state.createRealEstate.first_name = data.first_name
            state.createRealEstate.last_name = data.last_name
            state.createRealEstate.national_code = data.national_code
        },
        SET_SIGNUP_STEP2(state , data){
            state.singUpStep = data.step
            state.createRealEstate.real_estate.name = data.realEstateName
            state.createRealEstate.real_estate.telephone = data.tellPhone
            state.createRealEstate.real_estate.license_id = data.licenseId
            state.createRealEstate.real_estate.license_picture = data.imageSrc
            state.createRealEstate.real_estate.field_of_activity = data.activitySelected
        },
        SET_SIGNUP_STEP3(state , data){
            state.singUpStep = data.step
            state.createRealEstate.real_estate.province = data.province
            state.createRealEstate.real_estate.city = data.city
            state.createRealEstate.real_estate.area_name = data.areaName
            state.createRealEstate.real_estate.address = data.address
            state.createRealEstate.invite_token = data.invite_token
            state.createRealEstate.real_estate.geoposition = data.geoPosition
        },
    },
    actions: {
        getPhone({commit}, data) {
            commit('SET_USER_PHONE', data)
        },

    }
}